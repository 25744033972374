import React from "react"
import { Link } from "gatsby"

import GridItem from "components/Grid/GridItem.jsx"
import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"


const NewsCard = ({link, title, subtitle, img, date, classes}) => {
    return (
        <GridItem sm={6} style={{borderBottom: '1px solid lightgrey', height: '700px', margin: '10px'}} >
            <Link to={link}>
            <h2 className="subtitle" style={{color: "#D74E09"}}>{title}</h2>
            <img 
        src={img}
        style={{width: '100%', height: '350px', objectFit: 'cover'}} 
        alt=""
        className={
            classes.imgRaised +
            " " +
            classes.imgRounded +
            " " +
            classes.imgFluid
          }/>
            </Link>
            <h4> 
            {subtitle}
            </h4>
            <h4> 
            {date}
            </h4>
      </GridItem>
    )
}

export default withStyles(typographyStyle)(NewsCard)
